/* Margin-Top Class */
.m-t-5{margin-top:5px;}
.m-t-10{margin-top:10px;}
.m-t-15{margin-top:15px;}
.m-t-20{margin-top:20px;}
.m-t-25{margin-top:25px;}
.m-t-30{margin-top:30px;}
.m-t-35{margin-top:35px;}
.m-t-40{margin-top:40px;}
.m-t-45{margin-top:45px;}
.m-t-50{margin-top:50px;}
.m-t-55{margin-top:55px;}
.m-t-60{margin-top:60px;}
.m-t-65{margin-top:65px;}
.m-t-70{margin-top:70px;}
.m-t-75{margin-top:75px;}
.m-t-80{margin-top:80px;}
.m-t-85{margin-top:85px;}
.m-t-90{margin-top:90px;}
.m-t-95{margin-top:95px;}
.m-t-100{margin-top:100px;}
.m-t-105{margin-top:105px;}
.m-t-110{margin-top:110px;}
.m-t-115{margin-top:115px;}
.m-t-120{margin-top:120px;}
.m-t-125{margin-top:125px;}
.m-t-130{margin-top:130px;}
.m-t-135{margin-top:135px;}
.m-t-140{margin-top:140px;}
.m-t-145{margin-top:145px;}
.m-t-150{margin-top:150px;}
.m-t-155{margin-top:155px;}
.m-t-160{margin-top:160px;}
.m-t-165{margin-top:165px;}
.m-t-170{margin-top:170px;}
.m-t-175{margin-top:175px;}
.m-t-180{margin-top:180px;}
.m-t-185{margin-top:185px;}
.m-t-190{margin-top:190px;}
.m-t-195{margin-top:195px;}
.m-t-200{margin-top:200px;}

/* Margin-Bottom Class */
.m-b-5{margin-bottom:5px;}
.m-b-10{margin-bottom:10px;}
.m-b-15{margin-bottom:15px;}
.m-b-20{margin-bottom:20px;}
.m-b-25{margin-bottom:25px;}
.m-b-30{margin-bottom:30px;}
.m-b-35{margin-bottom:35px;}
.m-b-40{margin-bottom:40px;}
.m-b-45{margin-bottom:45px;}
.m-b-50{margin-bottom:50px;}
.m-b-55{margin-bottom:55px;}
.m-b-60{margin-bottom:60px;}
.m-b-65{margin-bottom:65px;}
.m-b-70{margin-bottom:70px;}
.m-b-75{margin-bottom:75px;}
.m-b-80{margin-bottom:80px;}
.m-b-85{margin-bottom:85px;}
.m-b-90{margin-bottom:90px;}
.m-b-95{margin-bottom:95px;}
.m-b-100{margin-bottom:100px;}
.m-b-105{margin-bottom:105px;}
.m-b-110{margin-bottom:110px;}
.m-b-115{margin-bottom:115px;}
.m-b-120{margin-bottom:120px;}
.m-b-125{margin-bottom:125px;}
.m-b-130{margin-bottom:130px;}
.m-b-135{margin-bottom:135px;}
.m-b-140{margin-bottom:140px;}
.m-b-145{margin-bottom:145px;}
.m-b-150{margin-bottom:150px;}
.m-b-155{margin-bottom:155px;}
.m-b-160{margin-bottom:160px;}
.m-b-165{margin-bottom:165px;}
.m-b-170{margin-bottom:170px;}
.m-b-175{margin-bottom:175px;}
.m-b-180{margin-bottom:180px;}
.m-b-185{margin-bottom:185px;}
.m-b-190{margin-bottom:190px;}
.m-b-195{margin-bottom:195px;}
.m-b-200{margin-bottom:200px;}

/* Margin-Left Class */
.m-l-5{margin-left:5px;}
.m-l-10{margin-left:10px;}
.m-l-15{margin-left:15px;}
.m-l-20{margin-left:20px;}
.m-l-25{margin-left:25px;}
.m-l-30{margin-left:30px;}
.m-l-35{margin-left:35px;}
.m-l-40{margin-left:40px;}
.m-l-45{margin-left:45px;}
.m-l-50{margin-left:50px;}
.m-l-55{margin-left:55px;}
.m-l-60{margin-left:60px;}
.m-l-65{margin-left:65px;}
.m-l-70{margin-left:70px;}
.m-l-75{margin-left:75px;}
.m-l-80{margin-left:80px;}
.m-l-85{margin-left:85px;}
.m-l-90{margin-left:90px;}
.m-l-95{margin-left:95px;}
.m-l-100{margin-left:100px;}
.m-l-105{margin-left:105px;}
.m-l-110{margin-left:110px;}
.m-l-115{margin-left:115px;}
.m-l-120{margin-left:120px;}
.m-l-125{margin-left:125px;}
.m-l-130{margin-left:130px;}
.m-l-135{margin-left:135px;}
.m-l-140{margin-left:140px;}
.m-l-145{margin-left:145px;}
.m-l-150{margin-left:150px;}
.m-l-155{margin-left:155px;}
.m-l-160{margin-left:160px;}
.m-l-165{margin-left:165px;}
.m-l-170{margin-left:170px;}
.m-l-175{margin-left:175px;}
.m-l-180{margin-left:180px;}
.m-l-185{margin-left:185px;}
.m-l-190{margin-left:190px;}
.m-l-195{margin-left:195px;}
.m-l-200{margin-left:200px;}

/* Margin-Right Class */
.m-r-5{margin-right:5px;}
.m-r-10{margin-right:10px;}
.m-r-15{margin-right:15px;}
.m-r-20{margin-right:20px;}
.m-r-25{margin-right:25px;}
.m-r-30{margin-right:30px;}
.m-r-35{margin-right:35px;}
.m-r-40{margin-right:40px;}
.m-r-45{margin-right:45px;}
.m-r-50{margin-right:50px;}
.m-r-55{margin-right:55px;}
.m-r-60{margin-right:60px;}
.m-r-65{margin-right:65px;}
.m-r-70{margin-right:70px;}
.m-r-75{margin-right:75px;}
.m-r-80{margin-right:80px;}
.m-r-85{margin-right:85px;}
.m-r-90{margin-right:90px;}
.m-r-95{margin-right:95px;}
.m-r-100{margin-right:100px;}
.m-r-105{margin-right:105px;}
.m-r-110{margin-right:110px;}
.m-r-115{margin-right:115px;}
.m-r-120{margin-right:120px;}
.m-r-125{margin-right:125px;}
.m-r-130{margin-right:130px;}
.m-r-135{margin-right:135px;}
.m-r-140{margin-right:140px;}
.m-r-145{margin-right:145px;}
.m-r-150{margin-right:150px;}
.m-r-155{margin-right:155px;}
.m-r-160{margin-right:160px;}
.m-r-165{margin-right:165px;}
.m-r-170{margin-right:170px;}
.m-r-175{margin-right:175px;}
.m-r-180{margin-right:180px;}
.m-r-185{margin-right:185px;}
.m-r-190{margin-right:190px;}
.m-r-195{margin-right:195px;}
.m-r-200{margin-right:200px;}

/* Margin-Top To Bottom Class */
.mtb-5{margin:5px 0;}	
.mtb-10{margin:10px 0;}	
.mtb-15{margin:15px 0;}	
.mtb-20{margin:20px 0;}	
.mtb-25{margin:25px 0;}	
.mtb-30{margin:30px 0;}	
.mtb-35{margin:35px 0;}	
.mtb-40{margin:40px 0;}	
.mtb-45{margin:45px 0;}	
.mtb-50{margin:50px 0;}

/* Margin-Left To Right Class */
.mlr-5{margin:0	5px;}
.mlr-10{margin:0 10px;}
.mlr-15{margin:0 15px;}
.mlr-20{margin:0 20px;}
.mlr-25{margin:0 25px;}
.mlr-30{margin:0 30px;}
.mlr-35{margin:0 35px;}
.mlr-40{margin:0 40px;}
.mlr-45{margin:0 45px;}
.mlr-50{margin:0 50px;}


/* Padding-Top Class */
.p-t-5{padding-top:5px;}
.p-t-10{padding-top:10px;}
.p-t-15{padding-top:15px;}
.p-t-20{padding-top:20px;}
.p-t-25{padding-top:25px;}
.p-t-30{padding-top:30px;}
.p-t-35{padding-top:35px;}
.p-t-40{padding-top:40px;}
.p-t-45{padding-top:45px;}
.p-t-50{padding-top:50px;}
.p-t-55{padding-top:55px;}
.p-t-60{padding-top:60px;}
.p-t-65{padding-top:65px;}
.p-t-70{padding-top:70px;}
.p-t-75{padding-top:75px;}
.p-t-80{padding-top:80px;}
.p-t-85{padding-top:85px;}
.p-t-90{padding-top:90px;}
.p-t-95{padding-top:95px;}
.p-t-100{padding-top:100px;}
.p-t-105{padding-top:105px;}
.p-t-110{padding-top:110px;}
.p-t-115{padding-top:115px;}
.p-t-120{padding-top:120px;}
.p-t-125{padding-top:125px;}
.p-t-130{padding-top:130px;}
.p-t-135{padding-top:135px;}
.p-t-140{padding-top:140px;}
.p-t-145{padding-top:145px;}
.p-t-150{padding-top:150px;}
.p-t-155{padding-top:155px;}
.p-t-160{padding-top:160px;}
.p-t-165{padding-top:165px;}
.p-t-170{padding-top:170px;}
.p-t-175{padding-top:175px;}
.p-t-180{padding-top:180px;}
.p-t-185{padding-top:185px;}
.p-t-190{padding-top:190px;}
.p-t-195{padding-top:195px;}
.p-t-200{padding-top:200px;}

/* Padding-Bottom Class */
.p-b-5{padding-bottom:5px;}
.p-b-10{padding-bottom:10px;}
.p-b-15{padding-bottom:15px;}
.p-b-20{padding-bottom:20px;}
.p-b-25{padding-bottom:25px;}
.p-b-30{padding-bottom:30px;}
.p-b-35{padding-bottom:35px;}
.p-b-40{padding-bottom:40px;}
.p-b-45{padding-bottom:45px;}
.p-b-50{padding-bottom:50px;}
.p-b-55{padding-bottom:55px;}
.p-b-60{padding-bottom:60px;}
.p-b-65{padding-bottom:65px;}
.p-b-70{padding-bottom:70px;}
.p-b-75{padding-bottom:75px;}
.p-b-80{padding-bottom:80px;}
.p-b-85{padding-bottom:85px;}
.p-b-90{padding-bottom:90px;}
.p-b-95{padding-bottom:95px;}
.p-b-100{padding-bottom:100px;}
.p-b-105{padding-bottom:105px;}
.p-b-110{padding-bottom:110px;}
.p-b-115{padding-bottom:115px;}
.p-b-120{padding-bottom:120px;}
.p-b-125{padding-bottom:125px;}
.p-b-130{padding-bottom:130px;}
.p-b-135{padding-bottom:135px;}
.p-b-140{padding-bottom:140px;}
.p-b-145{padding-bottom:145px;}
.p-b-150{padding-bottom:150px;}
.p-b-155{padding-bottom:155px;}
.p-b-160{padding-bottom:160px;}
.p-b-165{padding-bottom:165px;}
.p-b-170{padding-bottom:170px;}
.p-b-175{padding-bottom:175px;}
.p-b-180{padding-bottom:180px;}
.p-b-185{padding-bottom:185px;}
.p-b-190{padding-bottom:190px;}
.p-b-195{padding-bottom:195px;}
.p-b-200{padding-bottom:200px;}

/* Padding-Left Class */
.p-l-5{padding-left:5px;}
.p-l-10{padding-left:10px;}
.p-l-15{padding-left:15px;}
.p-l-20{padding-left:20px;}
.p-l-25{padding-left:25px;}
.p-l-30{padding-left:30px;}
.p-l-35{padding-left:35px;}
.p-l-40{padding-left:40px;}
.p-l-45{padding-left:45px;}
.p-l-50{padding-left:50px;}
.p-l-55{padding-left:55px;}
.p-l-60{padding-left:60px;}
.p-l-65{padding-left:65px;}
.p-l-70{padding-left:70px;}
.p-l-75{padding-left:75px;}
.p-l-80{padding-left:80px;}
.p-l-85{padding-left:85px;}
.p-l-90{padding-left:90px;}
.p-l-95{padding-left:95px;}
.p-l-100{padding-left:100px;}
.p-l-105{padding-left:105px;}
.p-l-110{padding-left:110px;}
.p-l-115{padding-left:115px;}
.p-l-120{padding-left:120px;}
.p-l-125{padding-left:125px;}
.p-l-130{padding-left:130px;}
.p-l-135{padding-left:135px;}
.p-l-140{padding-left:140px;}
.p-l-145{padding-left:145px;}
.p-l-150{padding-left:150px;}
.p-l-155{padding-left:155px;}
.p-l-160{padding-left:160px;}
.p-l-165{padding-left:165px;}
.p-l-170{padding-left:170px;}
.p-l-175{padding-left:175px;}
.p-l-180{padding-left:180px;}
.p-l-185{padding-left:185px;}
.p-l-190{padding-left:190px;}
.p-l-195{padding-left:195px;}
.p-l-200{padding-left:200px;}

/* Padding-Right Class */
.p-r-5{padding-right:5px;}
.p-r-10{padding-right:10px;}
.p-r-15{padding-right:15px;}
.p-r-20{padding-right:20px;}
.p-r-25{padding-right:25px;}
.p-r-30{padding-right:30px;}
.p-r-35{padding-right:35px;}
.p-r-40{padding-right:40px;}
.p-r-45{padding-right:45px;}
.p-r-50{padding-right:50px;}
.p-r-55{padding-right:55px;}
.p-r-60{padding-right:60px;}
.p-r-65{padding-right:65px;}
.p-r-70{padding-right:70px;}
.p-r-75{padding-right:75px;}
.p-r-80{padding-right:80px;}
.p-r-85{padding-right:85px;}
.p-r-90{padding-right:90px;}
.p-r-95{padding-right:95px;}
.p-r-100{padding-right:100px;}
.p-r-105{padding-right:105px;}
.p-r-110{padding-right:110px;}
.p-r-115{padding-right:115px;}
.p-r-120{padding-right:120px;}
.p-r-125{padding-right:125px;}
.p-r-130{padding-right:130px;}
.p-r-135{padding-right:135px;}
.p-r-140{padding-right:140px;}
.p-r-145{padding-right:145px;}
.p-r-150{padding-right:150px;}
.p-r-155{padding-right:155px;}
.p-r-160{padding-right:160px;}
.p-r-165{padding-right:165px;}
.p-r-170{padding-right:170px;}
.p-r-175{padding-right:175px;}
.p-r-180{padding-right:180px;}
.p-r-185{padding-right:185px;}
.p-r-190{padding-right:190px;}
.p-r-195{padding-right:195px;}
.p-r-200{padding-right:200px;}

/* Width Class */
.wv-5{max-width:5px;min-width:5px;}
.wv-10{max-width:10px;min-width:10px;}
.wv-15{max-width:15px;min-width:15px;}
.wv-20{max-width:20px;min-width:20px;}
.wv-25{max-width:25px;min-width:25px;}
.wv-30{max-width:30px;min-width:30px;}
.wv-35{max-width:35px;min-width:35px;}
.wv-40{max-width:40px;min-width:40px;}
.wv-45{max-width:45px;min-width:45px;}
.wv-50{max-width:50px;min-width:50px;}
.wv-55{max-width:55px;min-width:55px;}
.wv-60{max-width:60px;min-width:60px;}
.wv-65{max-width:65px;min-width:65px;}
.wv-70{max-width:70px;min-width:70px;}
.wv-75{max-width:75px;min-width:75px;}
.wv-80{max-width:80px;min-width:80px;}
.wv-85{max-width:85px;min-width:85px;}
.wv-90{max-width:90px;min-width:90px;}
.wv-95{max-width:95px;min-width:95px;}
.wv-100{max-width:100px;min-width:100px;}
.wv-125{max-width:125px;min-width:125px;}
.wv-150{max-width:150px;min-width:150px;}
.wv-175{max-width:175px;min-width:175px;}
.wv-200{max-width:200px;min-width:200px;}
.wv-225{max-width:225px;min-width:225px;}
.wv-250{max-width:250px;min-width:250px;}
.wv-275{max-width:275px;min-width:275px;}
.wv-300{max-width:300px;min-width:300px;}
.wv-325{max-width:325px;min-width:325px;}
.wv-350{max-width:350px;min-width:350px;}
.wv-375{max-width:375px;min-width:375px;}
.wv-400{max-width:400px;min-width:400px;}
.wv-500{max-width:500px;min-width:500px;}
.wv-600{max-width:600px;min-width:600px;}


/* Height Class */
.hv-5{max-height:5px;min-height:5px;}
.hv-10{max-height:10px;min-height:10px;}
.hv-15{max-height:15px;min-height:15px;}
.hv-20{max-height:20px;min-height:20px;}
.hv-25{max-height:25px;min-height:25px;}
.hv-30{max-height:30px;min-height:30px;}
.hv-35{max-height:35px;min-height:35px;}
.hv-40{max-height:40px;min-height:40px;}
.hv-45{max-height:45px;min-height:45px;}
.hv-50{max-height:50px;min-height:50px;}
.hv-55{max-height:55px;min-height:55px;}
.hv-60{max-height:60px;min-height:60px;}
.hv-65{max-height:65px;min-height:65px;}
.hv-70{max-height:70px;min-height:70px;}
.hv-75{max-height:75px;min-height:75px;}
.hv-80{max-height:80px;min-height:80px;}
.hv-85{max-height:85px;min-height:85px;}
.hv-90{max-height:90px;min-height:90px;}
.hv-95{max-height:95px;min-height:95px;}
.hv-100{max-height:100px;min-height:100px;}
.hv-130{max-height:130px;min-height:130px;}
.hv-200{max-height:200px;min-height:200px;}
.hv-300{max-height:300px;min-height:300px;}
.hv-400{max-height:400px;min-height:400px;}
.hv-500{max-height:500px;min-height:500px;}
.hv-600{max-height:600px;min-height:600px;}

/* max-height class */
.mhv-100{max-height: 100px;}
.mhv-200{max-height: 200px;}
.mhv-300{max-height: 300px;}
.mhv-400{max-height: 400px;}
.mhv-450{max-height: 450px;}
.mhv-500{max-height: 500px;}
.mhv-550{max-height: 550px;}
.mhv-600{max-height: 600px;}
.mhv-700{max-height: 700px;}
.mhv-800{max-height: 800px;}
.mhv-900{max-height: 900px;}
.mhv-1000{max-height: 1000px;}
.mhv-1100{max-height: 1100px;}
.mhv-1200{max-height: 1200px;}


/* Font Weight */
.fw-100{font-weight: 100;}
.fw-200{font-weight: 200;}
.fw-300{font-weight: 300;}
.fw-400{font-weight: 400;}
.fw-500{font-weight: 500;}
.fw-600{font-weight: 600;}
.fw-700{font-weight: 700;}
.fw-800{font-weight: 800;}

/* Dot Line Dot */
.line-clamp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.line-clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.line-clamp-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.line-clamp-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.dot-line-max-30{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 30ch;
}
.dot-line-max-40{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 40ch;
    display: block;
}
.cursor-grab{
    cursor: grab;
}
.text-wrap{
    white-space: wrap;
}
.disable-btn{
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    opacity: 0.6;
}
/* Overfolw */
.overflow-auto{
    overflow: auto;
}
.overflow-x-auto{
    overflow-x: auto;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.overflow-y-auto{
    overflow-y: auto;
}
.overflow-y-hidden{
    overflow-y: hidden;
}

/* Custom Class */
.list-style-none{
    list-style: none;
}
.white-pre-line{
    white-space: pre-line;
}
.break-word{
    word-break: break-word;
}
.white-space-nowrap{
    white-space: nowrap;
}
.position-unset{
    position: unset;
}
.rounded-4{
    border-radius: .625rem;
}
/* For Textarea */
.textarea-none{
    resize: none;
}
